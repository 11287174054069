import React, { useEffect, useMemo, useState } from 'react';
import {
  Form, Modal, Radio, Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDebounce } from 'react-use';
import slug from 'slug';

import { ButtonV2, InputV2 } from 'components';

import { ICONS_CATEGORIES_MAP } from 'assets/icons/categories';
import { CATEGORIES_ICON_DEFAULT_SET } from 'constants/categories';
import { generateIconsList } from 'utils/generateIconsList';

import './style.less';
import { slugValidator } from 'utils/form';


const AdminCategoryForm = ({
  categories,
  buttonText,
  open,
  defaultValue,
  onClose,
  onFinish: onFinishInput,
  requestLoading,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [titleCategory, setTitleCategory] = useState(defaultValue?.title || '');
  const [isSlugChanged, setIsSlugChanged] = useState(false);
  const [isSlugDisabled, setIsSlugDisabled] = useState(true);
  const [slugCategory, setSlugCategory] = useState(defaultValue?.slug || '');
  const [warningTitleProps, setWarningTitleProps] = useState({});
  const [warningSlugProps, setWarningSlugProps] = useState({});
  const [disabledOkBtn, setDisableOkBtn] = useState(!defaultValue);
  const iconsList = useMemo(
    () => generateIconsList(CATEGORIES_ICON_DEFAULT_SET, ICONS_CATEGORIES_MAP),
    []
  );

  useEffect(() => {
    if (defaultValue) {
      form.setFieldsValue({
        title: defaultValue.title,
        slug: defaultValue.slug,
        icon: defaultValue.icon,
      });
    }
  }, [defaultValue]);

  const handleTitleCheckChange = (e) => {
    setTitleCategory(e.target.value);

    if (!isSlugChanged) {
      const slugVlue = slug(e.target.value);
      setSlugCategory(slugVlue);
      form.setFieldsValue({
        slug: slugVlue,
      });
    }
  };

  const handleOnFinish = (model) => {
    onFinishInput(model, form);
  };

  const handleCustomizeSlugClick = () => {
    setIsSlugDisabled(false);
  };

  const handleSlugCheckChange = (e) => {
    setSlugCategory(e.target.value);
    setIsSlugChanged(true);
  };

  useDebounce(
    () => {
      const similarCategories = categories.filter((x) => {
        if (
          titleCategory.trim() === ''
          || titleCategory.trim() === defaultValue?.title
        ) {
          return false;
        }
        return (
          x.title.toLocaleLowerCase()
          === titleCategory.trim().toLocaleLowerCase()
        );
      });

      if (similarCategories.length > 0) {
        setWarningTitleProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: 'Запись с таким наименованием уже создана',
        });
      } else {
        setWarningTitleProps({});
      }
    },
    1000,
    [titleCategory]
  );

  useDebounce(
    () => {
      const similarCategories = categories.filter((x) => {
        if (
          slugCategory.trim() === ''
          || slugCategory.trim() === defaultValue?.slug
        ) {
          return false;
        }
        return (
          x.slug.toLocaleLowerCase() === slugCategory.trim().toLocaleLowerCase()
        );
      });

      if (similarCategories.length > 0) {
        setWarningSlugProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: 'Запись с таким наименованием уже создана',
        });
      } else {
        setWarningSlugProps({});
      }
    },
    1000,
    [slugCategory]
  );

  const handleOnChange = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setDisableOkBtn(!!e.errorFields.length));
  };

  return (
    <Modal
      width={550}
      className="modal"
      title={`${defaultValue ? 'Редактировать' : 'Добавить'} категорию`}
      onCancel={onClose}
      open={open}
      footer={[
        <>
          <ButtonV2 onClick={onClose} disabled={requestLoading}>
            Отменить
          </ButtonV2>
          <Tooltip title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}>
            <span style={{ marginLeft: 8 }}>
              <ButtonV2
                className={disabledOkBtn ? 'invalid-button' : ''}
                disabled={disabledOkBtn}
                type="primary"
                onClick={form.submit}
                loading={requestLoading}
              >
                Сохранить
              </ButtonV2>
            </span>
          </Tooltip>
        </>,
      ]}
    >
      <Form
        initialValues={defaultValue}
        layout="vertical"
        form={form}
        validateMessages={{ required: 'Обязательное поле' }}
        onValuesChange={handleOnChange}
        onFinish={handleOnFinish}
        {...rest}
      >
        <Form.Item
          rules={[
            { type: 'string', required: true, message: 'Поле должно содержать текст' },
            {
              pattern: /^(?=.*[A-Za-zА-Яа-я])([A-Za-zА-Яа-я0-9\W]+)$/,
              message: 'Поле может содержать только текст',
            },
          ]}
          {...warningTitleProps}
          name="title"
          label="Название категории"
        >
          <InputV2
            maxLength={25}
            placeholder="Название"
            onChange={handleTitleCheckChange}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { type: 'string', required: true, message: 'Поле должно содержать текст' },
            { validator: slugValidator }
          ]}
          {...warningSlugProps}
          name="slug"
          label={(
            <Tooltip title="Используется для URL адреса. Может содержать латиницу, цифры и тире">
              <span>
                Технический псевдоним&nbsp;
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
          )}
        >
          <InputV2
            maxLength={25}
            placeholder="example-name"
            disabled={isSlugDisabled}
            onChange={handleSlugCheckChange}
            addonAfter={
              isSlugDisabled && (
                <ButtonV2 onClick={handleCustomizeSlugClick} type="link">
                  Задать свой
                </ButtonV2>
              )
            }
          />
        </Form.Item>
        <Form.Item
          rules={
            [{ type: 'string', required: true }]
          }
          name="icon"
          label="Выберите иконку"
        >
          <Radio.Group className="admin-categories-radio-group">
            {iconsList.map((item) => {
              const IconNew = item.icon;
              return (
                <Radio
                  className="admin-categories-radio-option"
                  value={item.value}
                  key={item.key}
                >
                  <IconNew />
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminCategoryForm;
