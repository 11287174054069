import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import {
  Divider, Form, Modal, Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import slug from 'slug';

import { ButtonV2, InputV2 } from 'components';
import FileCrop from 'components/file-crop';
import OOCTextEditor from 'components/text-editor';
import { maxLength } from 'utils/react-quill';

import { phoneValidationInternational, urlValidation, slugValidator } from 'utils/form';
import './style.less';


const phoneRules = {
  rules: [
    {
      pattern: phoneValidationInternational,
      message: 'Введён некорректный номер телефона!',
    },
  ],
};

const validateMessages = {
  required: 'Поле "${label}" обязательно!',
  types: {
    email: 'Введён некорректный адрес электронной почты!',
    url: 'Введён некорректный URL-адрес!',
  },
};
/* eslint-enable no-template-curly-in-string */

const AdminVendorsPane = ({
  vendors,
  defaultValue: initialValuesProps,
  buttonText,
  onFinish: onFinishInput,
  modalOpen,
  onClose,
  requestLoading,
  ...rest
}) => {
  const [form] = Form.useForm();

  const [nameVendor, setNameVendor] = useState('');
  const [emailVendor, setEmailVendor] = useState('');
  const [isSlugChanged, setIsSlugChanged] = useState(false);
  const [isSlugDisabled, setIsSlugDisabled] = useState(true);
  const [siteUrl, setSiteUrl] = useState('');
  const [disabledOkBtn, setDisableOkBtn] = useState(!initialValuesProps);
  const [slugVendor, setSlugVendor] = useState('');
  const [warningTitleProps, setWarningTitleProps] = useState({});
  const [warningSlugProps, setWarningSlugProps] = useState({});
  const [warningEmailProps, setWarningEmailProps] = useState({});

  const [logo, setLogo] = useState(initialValuesProps?.logo);
  const [completedCrop, setCompletedCrop] = useState();
  const [imageRealSize, setImageRealSize] = useState();
  const [fileCropRefresh, setFileCropRefresh] = useState(0);

  useEffect(() => {
    if (!initialValuesProps) {
      form.resetFields();
      return;
    }
    setLogo(initialValuesProps?.logo);
    form.setFieldsValue({
      name: initialValuesProps.name,
      slug: initialValuesProps.slug,
      email: initialValuesProps.email,
      siteUrl: initialValuesProps.siteUrl,
      logo: initialValuesProps.logo,
      shortDescription: initialValuesProps.shortDescription,
      description: initialValuesProps.description,
      company: initialValuesProps.company,
    });
  }, [initialValuesProps]);

  useDebounce(
    () => {
      const similarVendors = vendors.filter((x) => {
        if (
          (slugVendor.trim() === '' && x.slug)
          || slugVendor.trim() === initialValuesProps?.slug.trim()
        ) {
          return false;
        }
        return x.slug.toLocaleLowerCase() === slugVendor.toLocaleLowerCase();
      });

      if (similarVendors.length > 0) {
        setWarningSlugProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: 'Запись с таким наименованием уже создана',
        });
      } else {
        setWarningSlugProps({});
      }
    },
    1000,
    [slugVendor]
  );

  useDebounce(
    () => {
      const similarVendors = vendors.filter((x) => {
        if (
          nameVendor.trim() === ''
          || nameVendor.trim() === initialValuesProps?.name.trim()
        ) {
          return false;
        }
        return (
          x.name.toLocaleLowerCase() === nameVendor.trim().toLocaleLowerCase()
        );
      });

      if (similarVendors.length > 0) {
        setWarningTitleProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: 'Запись с таким наименованием уже создана',
        });
      } else {
        setWarningTitleProps({});
      }
    },
    1000,
    [nameVendor]
  );

  useDebounce(
    () => {
      const similarVendors = vendors.filter((x) => {
        if (
          emailVendor.trim() === ''
          || emailVendor.trim() === initialValuesProps?.email.trim()
        ) {
          return false;
        }
        return x.email.toLocaleLowerCase() === emailVendor.toLocaleLowerCase();
      });

      if (similarVendors.length > 0) {
        setWarningEmailProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: `Email не должен совпадать с имеющимся: ${similarVendors[0].email}`,
        });
      } else {
        setWarningEmailProps({});
      }
    },
    1000,
    [emailVendor]
  );

  const handleOnFinish = async (model) => {
    await onFinishInput(
      {
        ...model,
        stage: initialValuesProps ? initialValuesProps.stage : 'draft',
        completedCrop: completedCrop
          ? { ...completedCrop, ...imageRealSize }
          : null,
        cropParameters: initialValuesProps
          ? initialValuesProps.logoSets?.cropParameters
          : undefined,
        logo,
      },
      form
    );

    if (!initialValuesProps) {
      setFileCropRefresh((prev) => prev + 1);
    }
  };

  const handleNameCheckChange = (e) => {
    setNameVendor(e.target.value);

    if (!isSlugChanged) {
      const slugVlue = slug(e.target.value);
      setSlugVendor(slugVlue);
      form.setFieldsValue({
        slug: slugVlue,
      });
    }
  };

  const handleCustomizeSlugClick = () => {
    setIsSlugDisabled(false);
  };

  const handleSlugCheckChange = (e) => {
    setSlugVendor(e.target.value);
    setIsSlugChanged(true);
  };

  const onFileCropChange = (e) => {
    setLogo(e[0]?.originFileObj ? e[0]?.originFileObj : e[0]?.name);
  };

  const validateFileList = (_, value) => {
    if (!value || (typeof value === 'object' && !value.file)) {
      return Promise.reject(new Error('Поле "Логотип" обязательно!'));
    }
    if (typeof value === 'object' && value.file.size / 1024 / 1024 > 3) {
      return Promise.reject(new Error('Файл не должен превышать 3 МБ'));
    }
    if (typeof value === 'object' && !value.file.type.includes('image/')) {
      return Promise.reject(new Error('Файл должен быть изображением'));
    }
    return Promise.resolve();
  };

  const setPhone = (name, phone) => {
    const maskedPhone = phone[0] === '+' || phone.length < 1 ? phone : `+${phone}`;
    form.setFieldValue(name, maskedPhone);
  };

  const handleOnChange = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setDisableOkBtn(!!e.errorFields.length));
  };

  const handleUrlChange = (name, url) => {
    const trimmedUrl = url.trim();
    const value = trimmedUrl.replace('https://', '');
    let urlMask = `https://${value}`;

    if (value === '') {
      urlMask = '';
    } else if (
      siteUrl.startsWith('https://')
        && !trimmedUrl.startsWith('https://')
        && trimmedUrl.length > 1
    ) {
      urlMask = siteUrl;
    }

    form.setFieldValue(name, urlMask);
    setSiteUrl(urlMask);
    handleOnChange();
  };

  return (
    <Modal
      title={`${initialValuesProps ? 'Редактировать' : 'Добавить'} вендора`}
      onCancel={onClose}
      open={modalOpen}
      width={550}
      footer={[
        <>
          <ButtonV2 onClick={onClose} disabled={requestLoading}>Отменить</ButtonV2>
          <Tooltip
            title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}
          >
            <span style={{ marginLeft: 8 }}>
              <ButtonV2
                loading={requestLoading}
                className={disabledOkBtn ? 'invalid-button' : ''}
                disabled={disabledOkBtn}
                type="primary"
                onClick={form.submit}
              >
                Сохранить
              </ButtonV2>
            </span>
          </Tooltip>
        </>,
      ]}
    >
      <Form
        initialValues={initialValuesProps}
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        onFinish={handleOnFinish}
        onValuesChange={handleOnChange}
        {...rest}
      >
        <Form.Item
          {...warningTitleProps}
          name="name"
          label="Название вендора"
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Поле должно содержать текст',
            },
            {
              pattern: /^(?=.*[A-Za-zА-Яа-я])([A-Za-zА-Яа-я0-9\W]+)$/,
              message: 'Поле может содержать только текст',
            },
          ]}
        >
          <InputV2
            maxLength={25}
            onChange={handleNameCheckChange}
            placeholder="Название"
          />
        </Form.Item>
        <Form.Item
          {...warningSlugProps}
          rules={[
            {
              type: 'string',
              required: true,
              message: 'Поле должно содержать текст',
            },
            { validator: slugValidator },
          ]}
          name="slug"
          label={(
            <Tooltip title="Используется для URL адреса. Может содержать латиницу, цифры и тире">
              <span>
                Технический псевдоним&nbsp;
                <QuestionCircleOutlined />
              </span>
            </Tooltip>
          )}
        >
          <InputV2
            maxLength={25}
            placeholder="example-name"
            disabled={isSlugDisabled}
            onChange={handleSlugCheckChange}
            addonAfter={
              isSlugDisabled && (
                <ButtonV2 onClick={handleCustomizeSlugClick} type="link">
                  Задать свой
                </ButtonV2>
              )
            }
          />
        </Form.Item>
        <Form.Item
          {...warningEmailProps}
          rules={[
            {
              type: 'email',
              required: true,
              message: validateMessages.types.email,
            },
          ]}
          name="email"
          label="Email"
        >
          <InputV2
            onChange={(e) => setEmailVendor(e.target.value)}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: validateMessages.types.url },
            { pattern: urlValidation, message: validateMessages.types.url },
          ]}
          name="siteUrl"
          label="Ссылка"
        >
          <InputV2 placeholder="Ссылка на сайт" onChange={(e) => handleUrlChange('siteUrl', e.target.value)} />
        </Form.Item>
        <Form.Item
          rules={[maxLength(250, '"краткое описание"')]}
          name="shortDescription"
          label="Краткое описание"
        >
          <OOCTextEditor placeholder="Введите краткое описание" />
        </Form.Item>
        <Form.Item
          rules={[maxLength(1000, '"описание"')]}
          name="description"
          label="Описание"
        >
          <OOCTextEditor placeholder="Введите описание" />
        </Form.Item>
        <FileCrop
          rules={[{ validator: validateFileList, required: true }]}
          name="logo"
          maxSize={3}
          label="Логотип"
          defaultFileList={
            initialValuesProps && initialValuesProps.logo
              ? [
                {
                  uid: '1',
                  name: initialValuesProps.logo,
                  status: 'done',
                  url: initialValuesProps.logoUrl,
                  thumbUrl: initialValuesProps.logoUrl,
                },
              ]
              : []
          }
          completedCrop={completedCrop}
          refresh={fileCropRefresh}
          setCompletedCrop={setCompletedCrop}
          setImageRealSize={setImageRealSize}
          onChange={onFileCropChange}
        />

        <Divider plain>
          <h4 className="title">О компании</h4>
        </Divider>
        <Form.Item
          name={['company', 'name']}
          label="Юридическое наименование компании"
        >
          <InputV2 maxLength={50} placeholder="Введите наименование" />
        </Form.Item>
        <Form.Item
          name={['company', 'address']}
          label="Юридический адрес компании"
        >
          <InputV2 maxLength={150} placeholder="Введите адрес" />
        </Form.Item>

        <Form.Item
          rules={[{ type: 'email' }]}
          name={['company', 'email']}
          label="Email"
        >
          <InputV2
            onChange={(e) => setEmailVendor(e.target.value)}
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item
          {...phoneRules}
          name={['company', 'phone']}
          label="Контактный телефон"
        >
          <InputV2
            type="phone"
            placeholder="Введите телефон"
            onChange={(e) => setPhone(['company', 'phone'], e.target.value)}
          />
        </Form.Item>

        <Form.Item
          rules={[{ type: 'email' }]}
          name={['company', 'supportEmail']}
          label="Служба поддержки. Email"
        >
          <InputV2 placeholder="Email" />
        </Form.Item>

        <Form.Item
          {...phoneRules}
          name={['company', 'supportPhone']}
          label="Служба поддержки. Телефон"
        >
          <InputV2
            type="phone"
            placeholder="Введите телефон"
            onChange={(e) => setPhone(['company', 'supportPhone'], e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminVendorsPane;
