/**
 * formFields [{
 *  errors []
 *  name []
 *  touched bool
 *  validating bool
 *  value any
 * }]
 * @param {array[]} formFields
 * @returns {{name, value}}
 */
export const parseUserObj = (formFields) => {
  const obj = {};

  formFields.forEach((item) => {
    obj[item.name[0]] = item.value;
  });

  return obj;
};

export const emailValidation = new RegExp(/^((([0-9A-Za-z]{0}[-0-9A-z\.]{0,}[0-9A-Za-z]{0})|([0-9А-Яа-я]{0}[-0-9А-я\.]{0,}[0-9А-Яа-я]{0}))@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/u);
export const phoneValidation = new RegExp(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/);
export const phoneValidationInternational = new RegExp(/^\+?[1-9]\d{1,14}$/);
export const nameValidation = new RegExp(/^[а-яa-z][\s-.]?([а-яa-z]+[\s-.]){0,}?([-.]?[а-яa-z]+[\s-.])?[а-яa-z]+$/i);
export const valueValidation = new RegExp(/^[а-яa-z-.<>"(){}\[\]'`§«»„“—0-9][\s-.<>"(){}\[\]'`§«»„“—0-9]?([а-яa-z]+[\s-.<>"(){}\[\]'`§«»„“—0-9]){0,}?([-.<>"(){}\[\]'`§«»„“—0-9]?[а-яa-z]+[\s-.<>"(){}\[\]'`§«»„“—0-9])?[а-яa-z-.<>"(){}\[\]'`§«»„“—0-9 ]{0,}[а-яa-z-.<>"(){}\[\]'`§«»„“—0-9]$/i);
export const numberValidation = new RegExp(/^[0-9]+$/);
export const charactersValidation = new RegExp(/^[а-яa-z]+$/i);
export const urlValidation = new RegExp(/^(https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/);

export const slugValidator = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }
  if (/[а-яА-Я]/.test(value)) {
    return Promise.reject(
      new Error('Поле не может содержать символы кириллицы')
    );
  }
  if (!/^(?=.*[A-Za-z0-9])[A-Za-z0-9]+[A-Za-z0-9-]*$/.test(value) || /^\d+$/.test(value)) {
    return Promise.reject(new Error('Поле может содержать только текст'));
  }
  return Promise.resolve();
};

export const fileValidator = (value, required) => {
  if (typeof value !== 'string' && value?.file?.status === 'uploading') {
    return Promise.reject(new Error('Пожалуйста дождитесть загрузки файла'));
  }
  if (typeof value !== 'string' && value?.file?.status !== 'done' && required) {
    return Promise.reject(new Error('Пожалуйста выберите файл'));
  }
  return Promise.resolve();
};
