import dayjs from 'dayjs';
import XLSX from 'xlsx-js-style';
import { stageRu } from 'utils/consts';
import { getShortFio } from '../get-short-fio';


const roleRu = {
  admin: 'Администратор',
  user: 'Пользователь',
  author: 'Автор',
};

export const writeFile = ({
  fileName,
  records,
  merges = [],
  columns = [],
  rows = [],
}) => {
  const workBook = XLSX.utils.book_new();

  const workSheet = XLSX.utils.aoa_to_sheet(records);
  XLSX.utils.book_append_sheet(workBook, workSheet, fileName);

  workSheet['!merges'] = merges;
  workSheet['!cols'] = columns;
  workSheet['!rows'] = rows;

  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

export const writeReportFile = (filter, report) => {
  const fileName = `report_apps_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const dateString = !filter.dateStart && !filter.dateEnd
    ? 'За все время'
    : `${filter.dateStart || 'За все время'} - ${
      filter.dateEnd || 'За все время'
    }`;

  const records = [
    [
      'Отчёт о количестве загрузок дистрибутивов ПО из системы "Каталог ПО" (ЕМОП)',
    ],
    [''],
    ['Отчёт за период', '', '', dateString],
    ['Организация', '', '', filter.organization || '---'],
    ['Вендор', '', '', filter.vendor || '---'],
    ['Приложение', '', '', filter.application || '---'],
    [''],
    [
      '',
      '',
      '',
      '',
      '',
      'Всего',
      { v: 'Уникальными пользователями', s: { alignment: { wrapText: true } } },
    ],
    [
      '',
      filter.application ? 'Организация' : filter.vendor ? 'Приложение' : '',
    ],
  ];

  const merges = [
    { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } },
    { s: { r: 2, c: 3 }, e: { r: 2, c: 6 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 2 } },
    { s: { r: 3, c: 3 }, e: { r: 3, c: 6 } },
    { s: { r: 4, c: 0 }, e: { r: 4, c: 2 } },
    { s: { r: 4, c: 3 }, e: { r: 4, c: 6 } },
    { s: { r: 5, c: 0 }, e: { r: 5, c: 2 } },
    { s: { r: 5, c: 3 }, e: { r: 5, c: 6 } },

    { s: { r: 7, c: 5 }, e: { r: 8, c: 5 } },
    { s: { r: 7, c: 6 }, e: { r: 8, c: 7 } },
  ];

  let tableStartRow = 9;

  if (filter.application) {
    report.byApplication.forEach(({ organization, total, unique }) => {
      records.push([
        '',
        organization.value,
        '',
        '',
        '',
        total.toString(),
        unique.toString(),
      ]);
      merges.push(
        { s: { r: tableStartRow, c: 1 }, e: { r: tableStartRow, c: 4 } },
        { s: { r: tableStartRow, c: 6 }, e: { r: tableStartRow, c: 7 } }
      );
      tableStartRow += 1;
    });
  } else if (filter.vendor) {
    report.byVendor.forEach(({ application, total, unique }) => {
      records.push([
        '',
        {
          v: application?.title,
          s: {
            font: { color: application?.isDeleted ? { rgb: 'A6A6A6' } : {} },
          },
        },
        '',
        '',
        '',
        total.toString(),
        unique.toString(),
      ]);
      merges.push(
        { s: { r: tableStartRow, c: 1 }, e: { r: tableStartRow, c: 4 } },
        { s: { r: tableStartRow, c: 6 }, e: { r: tableStartRow, c: 7 } }
      );
      tableStartRow += 1;
    });
  } else {
    Object.values(report.byOrganization).forEach(
      ({ applications, total, unique }) => {
        const vendorTitle = applications[0].application?.vendor?.name || 'none';

        records.push([
          '',
          { v: `Вендор - ${vendorTitle}`, s: { font: { bold: true } } },
          '',
          '',
          '',
          { v: total, s: { font: { bold: true } } },
          { v: unique, s: { font: { bold: true } } },
        ]);
        merges.push(
          { s: { r: tableStartRow, c: 1 }, e: { r: tableStartRow, c: 4 } },
          { s: { r: tableStartRow, c: 6 }, e: { r: tableStartRow, c: 7 } }
        );
        tableStartRow += 1;

        applications.forEach(({ application, total, unique }) => {
          records.push([
            '',
            '',
            {
              v: application?.title,
              s: {
                font: {
                  color: application?.isDeleted ? { rgb: 'A6A6A6' } : {},
                },
              },
            },
            '',
            '',
            total.toString(),
            unique.toString(),
          ]);
          merges.push(
            { s: { r: tableStartRow, c: 2 }, e: { r: tableStartRow, c: 4 } },
            { s: { r: tableStartRow, c: 6 }, e: { r: tableStartRow, c: 7 } }
          );
          tableStartRow += 1;
        });
      }
    );
  }

  records.push(['']);
  tableStartRow += 1;

  records.push([
    '',
    '',
    '',
    '',
    'ИТОГО:',
    report.totalDownloads.toString(),
    report.uniqueDownloads.toString(),
  ]);
  merges.push({ s: { r: tableStartRow, c: 6 }, e: { r: tableStartRow, c: 7 } });
  tableStartRow += 1;

  records.push(['']);
  tableStartRow += 1;

  records.push(['Создан', dayjs().toISOString()]);
  tableStartRow += 1;

  writeFile({ fileName, records, merges });
};

export const writeApplicationsReportFile = async (report) => {
  const fileName = `report_appList_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const records = [
    [
      {
        v: 'Отчёт о количестве приложений опубликованных в системе "Каталог ПО" (ЕМОП)',
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      },
    ],
    [''],
    [
      { v: 'Дата формирования отчёта', s: { font: { bold: true } } },
      {
        v: dayjs().format('DD.MM.YYYY'),
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      },
    ],
    [
      { v: 'Всего приложений', s: { font: { bold: true } } },
      {
        v: report.applicationsTotal,
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      },
    ],
    [
      { v: 'Всего вендоров', s: { font: { bold: true } } },
      {
        v: report.vendorsTotal,
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      },
    ],
    [
      { v: 'Всего дистрибутивов', s: { font: { bold: true } } },
      {
        v: report.packagesTotal,
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      },
    ],
    [''],
    [
      { v: 'ID' },
      { v: 'Название приложения' },
      { v: 'Вендор' },
      { v: 'Статус' },
      { v: 'Дата публикации' },
      { v: 'Наличие дистрибутива' },
      { v: 'Автор записи' },
      { v: 'Аналоги' },
    ],
  ];

  const merges = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 6 } },
    { s: { r: 2, c: 1 }, e: { r: 2, c: 6 } },
    { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
    { s: { r: 4, c: 1 }, e: { r: 4, c: 6 } },
    { s: { r: 5, c: 1 }, e: { r: 5, c: 6 } },
    { s: { r: 6, c: 0 }, e: { r: 6, c: 6 } },
  ];

  const columns = [
    { wch: 27 },
    { wch: 35 },
    { wch: 30 },
    { wch: 20 },
    { wch: 15 },
    { wch: 22 },
    { wch: 25 },
    { wch: 10 },
  ];
  const rows = [
    { hpx: 15 },
    { hpx: 15 },
    { hpx: 15 },
    { hpx: 15 },
    { hpx: 15 },
    { hpx: 15 },
    { hpx: 15 },
    { hpx: 30 },
  ];

  report.applications.forEach((application) => {
    const hasPackage = application.packages?.find(
      (item) => item.packageType === 'main'
    );
    const fio = getShortFio({
      name: application?.userName,
      patronymic: application?.userPatronymic,
      surname: application?.userSurname,
    });
    const hasAnalogs = application.analogs?.length ? 'Да' : 'Нет';
    const publishedAt = application.stage === 'published'
      ? dayjs(application.publishedAt).format('DD.MM.YYYY')
      : '-';
    records.push([
      application.id,
      application?.title,
      application.vendor?.name,
      stageRu[application.stage],
      publishedAt,
      hasPackage ? 'Да' : 'Нет',
      fio,
      hasAnalogs,
    ]);
  });
  writeFile({
    fileName,
    records,
    merges,
    columns,
    rows,
  });
};

export const writeAuthorsReportFile = (data) => {
  const fileName = `authors_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const columns = [
    {
      title: 'ID записи',
      dataIndex: 'id',
    },
    {
      title: 'ФИО',
      render: (item) => `${item.surname} ${item.name} ${item.patronymic}`,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      render: (item) => roleRu[item.role],
    },
    {
      title: 'Организация',
      dataIndex: 'work',
      render: (item) => item.work.place,
    },
    {
      title: 'Дата назначения',
      dataIndex: 'roleAssignmentDate',
      render: (item) => dayjs(item.roleAssignmentDate).format('DD.MM.YYYY'),
    },
  ];

  const records = [columns.map((col) => col.title)];

  data.forEach((item) => {
    const row = columns.map((col) => {
      if (col.render) {
        return col.render(item);
      }
      return item[col.dataIndex];
    });
    records.push(row);
  });

  const columnWidths = [
    { wch: 40 },
    { wch: 50 },
    { wch: 20 },
    { wch: 40 },
    { wch: 20 },
  ];

  writeFile({ fileName, records, columns: columnWidths });
};

export const writeAuthorReportFile = (data) => {
  const fileName = `author_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const columns = [
    {
      title: 'Вендор',
      dataIndex: 'vendor',
      render: (item) => item.vendor.name,
    },
    {
      title: 'Приложение',
      dataIndex: 'title',
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      render: (item) => stageRu[item.stage],
    },
    {
      title: 'Наличие дистрибутива',
      dataIndex: 'packages',
      render: (item) => (item.packages?.length ? 'Да' : 'Нет'),
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      render: (item) => (item.stage === 'published'
        ? dayjs(item.publishedAt).format('DD.MM.YYYY')
        : '-'),
    },
  ];

  const records = [
    [
      {
        v: `Отчёт по приложениям за авторстовом ${data.fio}`,
        s: { font: { bold: true }, alignment: { horizontal: 'center' } },
      },
    ],
    [''],
    columns.map((col) => col.title),
  ];

  const merges = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];

  data.applications.forEach((item) => {
    const row = columns.map((col) => {
      if (col.render) {
        return col.render(item);
      }
      return item[col.dataIndex];
    });
    records.push(row);
  });

  const columnWidths = [
    { wch: 40 },
    { wch: 40 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
  ];

  writeFile({
    fileName,
    records,
    columns: columnWidths,
    merges,
  });
};

export const writeCategoryReportFile = (data) => {
  const fileName = `category_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Название категории',
      dataIndex: 'title',
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      render: (item) => stageRu[item.stage],
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      render: (item) => (item.stage === 'published'
        ? dayjs(item.publishedAt).format('DD.MM.YYYY')
        : '-'),
    },
    {
      title: 'Связанные приложение',
      dataIndex: 'applicationsCount',
    },
  ];

  const records = [columns.map((col) => col.title)];

  data?.forEach((item) => {
    const row = columns.map((col) => {
      if (col.render) {
        return col.render(item);
      }
      return item[col.dataIndex];
    });
    records.push(row);
  });

  const columnWidths = [
    { wch: 40 },
    { wch: 50 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
  ];

  writeFile({ fileName, records, columns: columnWidths });
};

export const writeVendorReportFile = (data) => {
  const fileName = `vendor_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Название вендора',
      dataIndex: 'name',
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      render: (item) => stageRu[item.stage],
    },
    {
      title: 'Дата публикации',
      dataIndex: 'publishedAt',
      render: (item) => (item.stage === 'published'
        ? dayjs(item.publishedAt).format('DD.MM.YYYY')
        : '-'),
    },
    {
      title: 'Связанные приложение',
      dataIndex: 'applicationsCount',
    },
    {
      title: 'Кем создан',
      render: ({ userName, userPatronymic, userSurname }) => getShortFio({
        name: userName,
        patronymic: userPatronymic,
        surname: userSurname,
      }),
    },
  ];

  const records = [columns.map((col) => col.title)];

  data.forEach((item) => {
    const row = columns.map((col) => {
      if (col.render) {
        return col.render(item);
      }
      return item[col.dataIndex];
    });
    records.push(row);
  });

  const columnWidths = [
    { wch: 40 },
    { wch: 50 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
  ];

  writeFile({ fileName, records, columns: columnWidths });
};

export const writeFeedbacksReportFile = (data) => {
  const fileName = `feedbacks_${dayjs().format('YYYY-MM-DDTHH-mm')}`;

  const getCreater = (item) => {
    const { passport } = item?.creator?.user_info || {};
    if (!passport) return '';
    return `${passport.sur_name} ${passport.name?.[0]}.`;
  };

  const columns = [
    {
      title: 'ID записи ',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Приложения',
      key: 'application',
      dataIndex: 'application',
      render: (item) => item.application.title,
    },
    {
      title: 'Автор отзыва',
      key: 'author',
      render: (item) => `${item.authorLastname} ${item.authorFirstname?.[0]}.`,
    },
    {
      title: 'Дата отзыва',
      dataIndex: 'publishedAt',
      render: (item) => (item.stage === 'published'
        ? dayjs(item.publishedAt).format('DD.MM.YYYY')
        : '-'),
    },
    {
      title: 'Статус',
      dataIndex: 'stage',
      render: (item) => stageRu[item.stage],
    },
    {
      title: 'Кем внесён',
      key: 'createdBy',
      render: (item) => getCreater(item),
    },
  ];


  const records = [columns.map((col) => col.title)];

  data.forEach((item) => {
    const row = columns.map((col) => {
      if (col.render) {
        return col.render(item);
      }
      return item[col.dataIndex];
    });
    records.push(row);
  });

  const columnWidths = [
    { wch: 40 },
    { wch: 50 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
  ];

  writeFile({ fileName, records, columns: columnWidths });
};
