/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useMemo } from 'react';
import { useDebounce } from 'react-use';
import {
  Col, Form, Select, Row, Tooltip, Radio, Modal
} from 'antd';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import slug from 'slug';

import {
  TabPane, Tabs, ButtonV2, InputV2
} from 'components';

import listFormatter from 'utils/antd-helpers/list-formatter';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';

import FileList from 'components/file-list';
import FileCrop from 'components/file-crop';
import FileUploader from 'components/file-uloader';
import OOCTextEditor from 'components/text-editor';
import { useDispatch, useSelector } from 'react-redux';
import { getConfiguratorOptions } from 'models/catalog/actions';
import {
  urlValidation, slugValidator, numberValidation, fileValidator
} from 'utils/form';
import { maxLength } from 'utils/react-quill';
import CharacteristicsForm from './characteristicsForm';

import './style.less';


const badgeOptions = [
  {
    value: 'none',
    label: 'Нет',
    key: 0,
  },
  {
    value: 'special',
    label: 'Специальное предложение',
    key: 1,
  },
  {
    value: 'discount',
    label: 'Скидка',
    key: 2,
  },
  {
    value: 'free',
    label: 'Бесплатно',
    key: 3,
  },
  {
    value: 'certificate',
    label: 'Сертификация',
    key: 4,
  },
  {
    value: 'academic',
    label: 'Академические лицензии',
    key: 5,
  },
];

/* eslint-disable no-template-curly-in-string */
const arrayRulesRequired = {
  rules: [
    {
      type: 'array',
      required: true,
    },
  ],
};

const stringRulesRequired = {
  rules: [
    {
      type: 'string',
      required: true,
    },
  ],
};

const validateMessages = {
  required: 'Поле "${label}" обязательно!',
  types: {
    email: 'Введён некорректный адрес электронной почты!',
    url: 'Введён некорректный URL-адрес!',
  },
};
/* eslint-enable no-template-curly-in-string */

const AdminApplicationsPane = ({
  applications,
  vendors,
  categories,
  defaultValue,
  buttonText,
  open,
  onClose,
  onFinish: onFinishInput,
  reqiestLoading,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('common');

  const dispatch = useDispatch();
  const configuratorOptions = useSelector(
    (state) => state.catalog.configuratorOptions
  );

  const [vendorsList, seVendorsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isSlugChanged, setIsSlugChanged] = useState(false);
  const [isSlugDisabled, setIsSlugDisabled] = useState(true);
  const [slugApplication, setSlugApplication] = useState('');
  const [titleApp, setTitleApp] = useState('');
  const [warningTitleProps, setWarningTitleProps] = useState({});
  const [warningSlugProps, setWarningSlugProps] = useState({});
  const [siteUrl, setSiteUrl] = useState('');
  const [paymentType, setPaymentType] = useState(defaultValue?.paymentType);
  const [isValidityPeriod, setIsValidityPeriod] = useState(
    defaultValue?.licenseExpires?.licenseType === 'limit'
  );
  const [isSpecialOffer, setIsSpecialOffer] = useState(
    defaultValue?.badge === 'special'
  );
  const [isValid, setIsValid] = useState(true);

  const [fileCropRefresh, setFileCropRefresh] = useState(0);
  const [logo, setLogo] = useState(defaultValue?.mainImage);
  const [completedCrop, setCompletedCrop] = useState();
  const [imageRealSize, setImageRealSize] = useState();
  const [disable, setDisable] = useState(false);
  const [dateOfRegistryRequired, setDateOfRegistryRequired] = useState(false);
  const [packageFileList, setPackageFileList] = useState(
    defaultValue?.packages
      ? defaultValue.packages.map((packageItem) => ({
        ...packageItem,
        uid: packageItem.fileName,
        name: packageItem.label,
        status: 'done',
      }))
      : []
  );

  useEffect(() => {
    dispatch(getConfiguratorOptions());
  }, [dispatch]);

  useEffect(() => {
    if (vendors) {
      seVendorsList(listFormatter(vendors));
    }
  }, [vendors, isSpecialOffer]);

  useEffect(() => {
    if (categories) {
      setCategoriesList(listFormatter(categories));
    }
  }, [categories]);

  useEffect(() => {
    // can't set from getValueProps for mode="multiple" don't know why
    if (defaultValue) {
      const { vendor, licenseExpires, registry } = defaultValue;
      const date = registry?.dateOfRegistry
        ? dayjs(registry.dateOfRegistry)
        : undefined;
      form.setFieldsValue({
        vendor: vendor.id,
        licenseExpires: {
          ...licenseExpires,
          unit: licenseExpires?.unit,
        },
        publicOffer: defaultValue.publicOffer,
        registry: {
          ...registry,
          dateOfRegistry: date,
        },
        descriptions: defaultValue.descriptions,
      });
      setDateOfRegistryRequired(Boolean(registry?.numOfRegistry?.length));
      form
        .validateFields()
        .then(() => setIsValid(false));
    }
    form.setFieldValue(
      'complectsOfDelivery',
      defaultValue?.complectsOfDelivery?.length
        ? defaultValue.complectsOfDelivery.map((el) => ({
          nomination: el.nomination,
          complectsType: el.complectsType,
        }))
        : [{ nomination: undefined, complectsType: undefined }]
    );
  }, [form, defaultValue]);

  const selectOptions = useMemo(() => {
    const options = {};
    configuratorOptions?.map((option) => {
      option.value.reduce((initialArray, configurator) => {
        initialArray?.push({
          value: configurator.id,
          label: configurator.value,
        });
        options[option.key] = initialArray;
        return initialArray;
      }, []);
    });
    return options;
  }, [configuratorOptions]);

  useDebounce(
    () => {
      const similarApplications = applications.filter((x) => {
        if (
          (slugApplication.trim() === '' && !x.slug)
          || slugApplication.trim() === defaultValue?.slug.trim()
        ) {
          return false;
        }
        return (
          x.slug.toLocaleLowerCase() === slugApplication.toLocaleLowerCase()
        );
      });

      if (similarApplications.length > 0) {
        setWarningSlugProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: 'Запись с таким наименованием уже создана',
        });
      } else {
        setWarningSlugProps({});
      }
    },
    1000,
    [slugApplication]
  );

  useDebounce(
    () => {
      const similarApps = applications.filter((x) => {
        if (
          titleApp.trim() === ''
          || titleApp.trim() === defaultValue?.title.trim()
        ) {
          return false;
        }
        return (
          x.title.toLocaleLowerCase() === titleApp.trim().toLocaleLowerCase()
        );
      });

      if (similarApps.length > 0) {
        setWarningTitleProps({
          hasFeedback: true,
          validateStatus: 'error',
          help: 'Запись с таким наименованием уже создана',
        });
      } else {
        setWarningTitleProps({});
      }
    },
    1000,
    [titleApp]
  );

  const handleOnFinish = async (model) => {
    const registry = model.registry?.numOfRegistry
      ? {
        ...model.registry,
        dateOfRegistry: model.registry.dateOfRegistry
          ? dayjs(model.registry.dateOfRegistry).toISOString()
          : undefined,
      }
      : undefined;
    const removeMainImage = defaultValue && defaultValue.id && !!defaultValue.mainImage && !logo;
    await onFinishInput(
      {
        ...model,
        registry,
        packages: packageFileList.map((packageItem) => ({
          label: packageItem.orig_name ?? packageItem.name ?? packageItem.label,
          fileName: packageItem.filename ?? packageItem.fileName,
          packageType: packageItem.packageType || 'main',
        })),
        completedCrop: completedCrop
          ? { ...completedCrop, ...imageRealSize }
          : null,
        mainImage: logo !== defaultValue?.mainImage ? logo : undefined,
        removeMainImage,
      }
    );

    if (!defaultValue) {
      setFileCropRefresh((prev) => prev + 1);
      setPackageFileList([]);
    }
  };

  const handleTitleCheckChange = (e) => {
    setTitleApp(e.target.value);

    if (!isSlugChanged) {
      const slugVlue = slug(e.target.value);

      setSlugApplication(slugVlue);
      form.setFieldsValue({
        slug: slugVlue,
      });
    }
  };

  const handleCustomizeSlugClick = () => {
    setIsSlugDisabled(false);
  };

  const handleSlugCheckChange = (e) => {
    setSlugApplication(e.target.value);
    setIsSlugChanged(true);
  };

  const onChangePackageFileList = (fileList) => {
    setPackageFileList(fileList);
  };

  const onFileCropChange = (e) => {
    setLogo(e[0]?.originFileObj ? e[0]?.originFileObj : e[0]?.name);
  };

  const handleOnChange = () => {
    const { paymentType: appPaymentType, badge, licenseExpires } = form.getFieldsValue();
    form
      .validateFields()
      .then(() => {})
      .catch((e) => setIsValid(!!e.errorFields.length));

    setPaymentType(appPaymentType);
    setIsSpecialOffer(badge === 'special');
    setIsValidityPeriod(licenseExpires?.licenseType === 'limit');
  };

  const isSimilarValues = useMemo(
    () => Object.keys(warningSlugProps),
    [warningSlugProps]
  );

  const contentValidator = (value, fieldName) => {
    const error = new Error(`Поле ${fieldName} обязательно!`);
    if (!value) {
      return Promise.reject(error);
    }
    const regex = /( |<([^>]+)>)/gi;
    const result = value.replace(regex, '');
    if (!result) {
      return Promise.reject(error);
    }

    return Promise.resolve();
  };

  const disabledOkBtn = isValid || !!isSimilarValues.length || disable;

  const handleUrlChange = (name, url) => {
    const trimmedUrl = url.trim();
    const value = trimmedUrl.replace('https://', '');
    let urlMask = `https://${value}`;

    if (value === '') {
      urlMask = '';
    } else if (
      siteUrl.startsWith('https://')
      && !trimmedUrl.startsWith('https://')
      && trimmedUrl.length > 1
    ) {
      urlMask = siteUrl;
    }

    form.setFieldValue(name, urlMask);
    setSiteUrl(urlMask);
    handleOnChange();
  };

  const priceInput = useMemo(() => {
    const show = paymentType === 'specified' || paymentType === 'price-from';
    const label = {
      'price-from': 'Цена ПО',
      specified: 'Цена ПО, (руб.)',
    };
    const rules = [
      {
        required: true,
        message: 'Поле "Стоимость" обязательно',
      },
    ];
    if (paymentType === 'specified') {
      rules.push({
        pattern: numberValidation,
        message: 'Поле может содержать только число',
      });
    }
    return { show, label: label[paymentType], rules };
  }, [paymentType]);

  const handlePublicOfferCahnge = (val) => {
    form.setFieldValue('publicOffer', val);
  };

  return (
    <Modal
      maskClosable={false}
      className="admin-applications-pane"
      title={`${defaultValue ? 'Редактирование' : 'Добавление'} записи`}
      width={992}
      open={open}
      onCancel={onClose}
      footer={[
        <>
          <ButtonV2 disabled={reqiestLoading} onClick={onClose}>Отменить</ButtonV2>
          <Tooltip
            title={disabledOkBtn ? 'Заполните все обязательные поля' : ''}
          >
            <span style={{ marginLeft: 8 }}>
              <ButtonV2
                className={disabledOkBtn ? 'invalid-button' : ''}
                disabled={disabledOkBtn}
                type="primary"
                onClick={form.submit}
                loading={reqiestLoading}
              >
                Сохранить
              </ButtonV2>
            </span>
          </Tooltip>
        </>,
      ]}
    >
      <Form
        initialValues={defaultValue}
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        onFinish={handleOnFinish}
        onValuesChange={handleOnChange}
        {...rest}
      >
        <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
          <TabPane forceRender tab="Общая информация" key="common">
            <Row gutter={24} align="top">
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'Поле должно содержать текст',
                    },
                    {
                      pattern: /^(?=.*[A-Za-zА-Яа-я])([A-Za-zА-Яа-я0-9\W]+)$/,
                      message: 'Поле может содержать только текст',
                    },
                  ]}
                  {...warningTitleProps}
                  name="title"
                  label="Название приложения"
                >
                  <InputV2
                    placeholder="Введите название приложения"
                    onChange={handleTitleCheckChange}
                    maxLength={25}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'Поле должно содержать текст',
                    },
                    { validator: slugValidator },
                  ]}
                  {...warningSlugProps}
                  name="slug"
                  label={(
                    <Tooltip title="Используется для URL адреса. Может содержать латиницу, цифры и тире">
                      <span>
                        Технический псевдоним&nbsp;
                        <QuestionCircleOutlined />
                      </span>
                    </Tooltip>
                  )}
                >
                  <InputV2
                    placeholder="example-name-change-it"
                    disabled={isSlugDisabled}
                    onChange={handleSlugCheckChange}
                    maxLength={25}
                    addonAfter={
                      isSlugDisabled && (
                        <ButtonV2
                          onClick={handleCustomizeSlugClick}
                          type="link"
                        >
                          Задать свой
                        </ButtonV2>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[maxLength(250, '"краткое описание приложения"')]}
                  type="string"
                  name="descriptionShort"
                  label="Краткое описание приложения"
                >
                  <InputV2
                    textarea
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                    placeholder="Введите превью-описание"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="vendor"
              rules={[
                {
                  required: true,
                },
              ]}
              label="Вендор"
            >
              <Select
                className="form-select"
                size="large"
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Начните вводить название"
                options={vendorsList}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>

            <Form.Item
              name="categories"
              {...arrayRulesRequired}
              label="Категория"
            >
              <Select
                mode="multiple"
                className="form-select"
                size="large"
                showSearch
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Выберите категорию"
                options={categoriesList}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
            <FileCrop
              maxSize={10}
              label="Логотип приложения"
              defaultFileList={
                defaultValue?.mainImage
                  ? [
                    {
                      uid: '1',
                      name: defaultValue.mainImage,
                      url: defaultValue.mainImageUrl,
                      thumbUrl: defaultValue.mainImageUrl,
                      status: 'done',
                    },
                  ]
                  : []
              }
              completedCrop={completedCrop}
              refresh={fileCropRefresh}
              setCompletedCrop={setCompletedCrop}
              setImageRealSize={setImageRealSize}
              onChange={onFileCropChange}
            />

            <Row gutter={24} align="top">
              <Col span={24}>
                <Form.Item
                  name="siteUrl"
                  rules={[
                    {
                      message: validateMessages.types.url,
                      pattern: urlValidation,
                    },
                  ]}
                  label="Сайт"
                >
                  <InputV2
                    placeholder="Ссылка на сайт приложения"
                    onChange={(e) => handleUrlChange('siteUrl', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <FileList
              label={(
                <Tooltip title="Архив должен содержать дистрибутив, инструкцию по установке и инструкцию пользователя">
                  <span>
                    Установочный пакет&nbsp;
                    <QuestionCircleOutlined />
                  </span>
                </Tooltip>
              )}
              rules={[
                {
                  validator: (_, value) => fileValidator(value, false),
                },
              ]}
              name="packages"
              defaultFileList={
                defaultValue?.packages
                  ? defaultValue.packages.map((packageItem) => ({
                    uid: '1',
                    name: packageItem.label,
                    url: packageItem.fullUrl,
                    status: 'done',
                  }))
                  : []
              }
              maxCount={1}
              setDisable={setDisable}
              onChange={onChangePackageFileList}
            />
          </TabPane>

          <TabPane forceRender tab="Характеристики" key="characteristic">
            <CharacteristicsForm
              selectOptions={selectOptions}
              isValidityPeriod={isValidityPeriod}
              arrayRulesRequired={arrayRulesRequired}
              stringRulesRequired={stringRulesRequired}
              dateOfRegistryRequired={dateOfRegistryRequired}
              setDateOfRegistryRequired={setDateOfRegistryRequired}
              article={defaultValue?.article}
            />
          </TabPane>

          <TabPane forceRender tab="Описание" key="description">
            <Form.List name="descriptions">
              {(fields, { add, remove }, ...t) => (
                <>
                  {fields.map(
                    ({
                      key, name, fieldKey, ...restField
                    }, index) => (
                      <Row key={key} gutter={24} align="middle">
                        <Col span={24}>
                          <div className="form-block-group-border">
                            <div className="block-group-caption">
                              <div className="text-bg">
                                Вкладка #
                                {index + 1}
                              </div>
                              <div>
                                <DuplicateIcon
                                  className="duplicate-btn"
                                  onClick={() => add(
                                    form.getFieldValue('descriptions')?.[name]
                                  )}
                                />
                                <TrashIcon
                                  className="trash-btn"
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </div>
                            <Form.Item
                              rules={[{ required: true, type: 'string' }]}
                              {...restField}
                              name={[name, 'titleShort']}
                              fieldKey={[fieldKey, 'titleShort']}
                              label="Название вкладки"
                            >
                              <InputV2
                                maxLength={25}
                                placeholder="Введите название"
                              />
                            </Form.Item>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  validator: (_, value) => contentValidator(value, 'Контент'),
                                },
                              ]}
                              {...restField}
                              name={[name, 'content']}
                              fieldKey={[fieldKey, 'content']}
                              label="Контент"
                            >
                              <OOCTextEditor placeholder="Ввод текста" />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    )
                  )}
                  <Form.Item
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonV2
                      type="link"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Добавить еще вкладку
                    </ButtonV2>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </TabPane>

          <TabPane forceRender tab="Аналоги" key="analogs">
            <Form.List name="analogs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({
                    key, name, fieldKey, ...restField
                  }) => (
                    <Row key={key} gutter={32} align="middle">
                      <Col span={22}>
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          fieldKey={[fieldKey, 'name']}
                          label="Название аналога"
                        >
                          <InputV2 placeholder="Введите название" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <TrashIcon
                          className="trash-btn"
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonV2
                      type="link"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Добавить аналог
                    </ButtonV2>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </TabPane>

          <TabPane forceRender tab="Оплата" key="payment">
            <Row gutter={32} align="middle">
              <Col span={24}>
                <Form.Item
                  type="string"
                  name="paymentType"
                  label="Оплата"
                  rules={[
                    {
                      required: true,
                      message: 'Поле "Оплата" обязательно',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="specified">Указать стоимость</Radio>
                    <Radio value="price-from">Указать стоимость ОТ</Radio>
                    <Radio value="price-on-request">По запросу</Radio>
                    <Radio value="free">Бесплатно</Radio>
                    <Radio value="no-price">Не указан</Radio>
                  </Radio.Group>
                </Form.Item>
                {priceInput.show && (
                  <Form.Item
                    name="price"
                    label={priceInput.label}
                    rules={priceInput.rules}
                  >
                    <InputV2 maxLength={100} controls={false} placeholder="Введите cтоимость" />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Form.Item
              name="badge"
              label="Выберите тип специального предложения"
            >
              <Select
                className="form-select"
                size="large"
                showSearch
                placeholder="Начните вводить название предложения"
                options={badgeOptions}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
            {isSpecialOffer && (
              <Form.Item
                name="specialOffer"
                label="Специальное предложение"
                rules={[
                  {
                    required: true,
                    message: 'Поле "Специальное предложение" обязательно',
                  },
                ]}
              >
                <Select
                  className="form-select"
                  size="large"
                  placeholder="Выберите специальное предложение"
                  options={selectOptions.special}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            )}
            <FileUploader
              rules={[
                {
                  required: true,
                  validator: (_, value) => fileValidator(value, true),
                },
              ]}
              type="file"
              accept=".pdf"
              format=".pdf"
              name="publicOffer"
              label="Договор оферты"
              onChange={handlePublicOfferCahnge}
              maxSize={10}
              initialSrc={defaultValue?.publicOffer}
            />
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default AdminApplicationsPane;
