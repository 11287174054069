/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import {
  message,
  Row,
  Modal,
  Spin,
  Space,
  Form,
  Input,
} from 'antd';
import {
  ArrowDownOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg';
import { ButtonV2 } from 'components';
import { calcCropParams } from 'components/upload-cropper';
import {
  getApplications,
  getPaginationApplications,
  getVendors,
  getCategories,
} from 'models/catalog/actions';
import { TEMP_SOFTWARE_CATALOG_API } from 'models/catalog/api';
import {
  post as oPost,
  remove as oDelete,
  put as oPut,
  errorMessageCatcher,
  get as oGet,
  patch as oPatch,
} from 'utils/request';
import jsonToFormData from 'utils/json-to-form-data';
import getFileBinary from 'utils/antd-helpers/get-file-binary';
import { getUserRole, ROLE_ADMIN, getAccessData } from 'utils/auth';
import { writeApplicationsReportFile } from 'utils/xlsx/writeXlsx';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

import AdminApplicationFormNew from './formNew';
import AdminAppsPaneFilters from './filters';
import AdminAppsPaneTable from './table';

import './style.less';


const loadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

/* TEMP create functions */
export const createApp = (formData) => {
  const url = '/applications';

  return oPost(
    `${url}`,
    formData,
    {
      accept: 'application/json',
    },
    false,
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const updateApp = (data, id) => {
  const url = `/applications/${id}`;

  return oPut(
    `${url}`,
    data,
    {
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const removeAppLogo = (id) => {
  const url = `/applications/delete-main-image/${id}`;

  return oDelete(
    url,
    {
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const deleteApp = (id) => {
  const url = `/applications/${id}`;

  return oDelete(
    url,
    {
      'content-type':
        'application/json;odata.metadata=minimal;odata.streaming=true',
      accept: 'application/json;odata.metadata=minimal;odata.streaming=true',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

export const getApplicationRecord = () => {
  const url = '/applications/report';

  return oGet(
    `${url}`,
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const changeStage = (data, id) => {
  const url = `/applications/${id}/stage`;

  return oPatch(
    url,
    JSON.stringify(data),
    {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    TEMP_SOFTWARE_CATALOG_API
  );
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
  stage: 'draft,published',
};


const AdminApplicationsPane = () => {
  const dispatch = useDispatch();

  const userRole = getUserRole();
  const user = getAccessData();
  const [filterForm] = Form.useForm();
  const [searchTitle, setSearchTitle] = useState('');
  const [skipDebounce, setSkipDebounce] = useState(true);

  const [changesCounter, setChangesCounter] = useState(0);
  const [modalConfig, setModalConfig] = useState({ open: false });
  const [removeConfig, setRemoveConfig] = useState({ open: false });
  const [applicationParams, setApplicationParams] = useState(DEFAULT_PARAMS);
  const [modalLoading, setModalLoading] = useState(false);

  const applications = useSelector((state) => state.catalog.applications);
  const { items, totalCount, loading } = useSelector((state) => state.catalog.paginationApplications);
  const vendors = useSelector((state) => state.catalog.vendors);
  const categories = useSelector((state) => state.catalog.categories);

  useEffect(() => {
    dispatch(getApplications({ stage: 'draft,published' }));
    dispatch(getVendors());
    dispatch(getCategories());
  }, [dispatch, changesCounter]);

  useEffect(() => {
    dispatch(getPaginationApplications(applicationParams));
  }, [dispatch, changesCounter, applicationParams]);

  const handleReportApplication = () => {
    getApplicationRecord().then((response) => writeApplicationsReportFile(response));
  };

  const openModal = (defaultValue) => {
    setModalConfig({
      open: true,
      defaultValue,
    });
  };

  const closeModal = () => {
    setModalConfig({ open: false });
  };

  const onAddAppForm = async (model) => {
    setModalLoading(true);
    const { mainImage, completedCrop, ...rest } = model;

    const mainImageCropParams = calcCropParams(completedCrop);

    const formData = jsonToFormData({
      ...rest,
      mainImage: getFileBinary(mainImage),
      mainImageCropParams,
    });

    await createApp(formData).then(() => {
      message.success({
        content: 'Приложение добавлено',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      closeModal();
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const onEditAppForm = async (model, id, removeMainImage) => {
    setModalLoading(true);
    const {
      mainImage, completedCrop, mainImageSets, ...rest
    } = model;

    const mainImageCropParams = calcCropParams(completedCrop);

    const formData = jsonToFormData({
      ...rest,
      mainImage: getFileBinary(mainImage),
      mainImageCropParams,
    });

    if (removeMainImage) {
      await removeAppLogo(id);
    }

    await updateApp(formData, id).then(() => {
      message.success({
        content: 'Приложение обновлено',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      closeModal();
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const handleDeleteAppClick = async (id) => {
    setModalLoading(true);
    await deleteApp(id).then(() => {
      message.success({
        content: 'Приложение удалено',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
      setRemoveConfig({ open: false });
    }, errorMessageCatcher);
    setModalLoading(false);
  };

  const onFinish = ({ removeMainImage, ...model }) => {
    if (!modalConfig.defaultValue) {
      return onAddAppForm(model);
    }
    return onEditAppForm(
      {
        ...model,
        mainImageSets: modalConfig.defaultValue?.mainImageSets,
      },
      modalConfig.defaultValue.id,
      removeMainImage
    );
  };

  const handleChangeStage = (app) => {
    const stage = app.stage === 'draft' ? 'published' : 'draft';
    changeStage({ stage }, app.id).then(() => {
      message.success({
        content: 'Приложение обновлено',
        icon: <CheckOutlined />
      });
      setChangesCounter((state) => state + 1);
    }, errorMessageCatcher);
  };

  const getItems = (item) => {
    const publishItemText = item.stage === 'draft' ? 'Опубликовать' : 'Снять с публикации';
    const hasAccess = userRole === ROLE_ADMIN || item.userCreator === user.id;
    const dropdownItems = [
      {
        key: '1',
        label: <div>Редактировать</div>,
        disabled: !hasAccess,
        onClick: () => openModal(item),
      },
      {
        key: '2',
        label: <div>{publishItemText}</div>,
        disabled: !hasAccess,
        onClick: () => handleChangeStage(item),
      },
    ];

    if (item.packages?.[0]?.fullUrl) {
      dropdownItems.push({
        key: '3',
        label: <div>Скачать установочный пакет</div>,
        onClick: () => window.open(item.packages[0].fullUrl),
      });
    }

    if (item.stage === 'draft') {
      dropdownItems.push({
        key: '4',
        label: <div>Удалить</div>,
        disabled: !hasAccess,
        onClick: () => setRemoveConfig({
          open: true,
          app: item,
        }),
      });
    }
    return dropdownItems;
  };

  const setApplicationSortHandler = (sort) => {
    setApplicationParams((prev) => ({
      ...prev,
      sort,
    }));
  };

  const onPaginationChange = (params) => {
    setApplicationParams((prev) => ({ ...prev, ...params }));
  };

  const resetFields = () => {
    setApplicationParams(DEFAULT_PARAMS);
  };

  const onFilter = (params) => {
    setApplicationParams((prev) => ({ ...prev, ...params }));
  };

  useDebounce(
    () => {
      if (skipDebounce) {
        setSkipDebounce(false);
        return;
      }
      setApplicationParams((prev) => ({ ...prev, title: searchTitle, page: 1 }));
    },
    500,
    [searchTitle]
  );

  const removeCancel = () => {
    if (modalLoading) {
      return;
    }
    setRemoveConfig({ open: false });
  };

  return (
    <div className="admin-form admin-applications-pane">
      <div className="admin-dashboard-pane__row">
        <Form form={filterForm} className="filter filter-with-buttons">
          <Row justify="space-between" className="w-full">
            <Form.Item name="title" className="filter-item filter-search">
              <Input
                placeholder="Поиск по названию"
                suffix={<SearchIcon className="gray" />}
                onChange={(e) => setSearchTitle(e.target.value)}
                autoComplete="off"
              />
            </Form.Item>
            <Space>
              <ButtonV2
                type="link"
                icon={<ArrowDownOutlined />}
                onClick={handleReportApplication}
              >
                Сформировать отчёт
              </ButtonV2>
              <ButtonV2
                type="link"
                icon={<PlusOutlined />}
                onClick={() => openModal()}
              >
                Добавить новую запись
              </ButtonV2>
              {modalConfig.open && (
                <AdminApplicationFormNew
                  {...modalConfig}
                  onClose={closeModal}
                  applications={applications}
                  vendors={vendors}
                  categories={categories}
                  onFinish={onFinish}
                  reqiestLoading={modalLoading}
                />
              )}
            </Space>
          </Row>
          <AdminAppsPaneFilters
            vendors={vendors}
            categories={categories}
            filterForm={filterForm}
            onFilter={onFilter}
            onReset={resetFields}
          />
        </Form>
      </div>

      <Spin indicator={loadingIcon} spinning={loading}>
        <AdminAppsPaneTable
          items={items}
          totalCount={totalCount}
          params={applicationParams}
          onSortParams={setApplicationSortHandler}
          onChangeParams={onPaginationChange}
          getItems={getItems}
        />
      </Spin>

      <Modal
        open={removeConfig.open}
        okText="Удалить"
        onCancel={removeCancel}
        title="Удалить запись"
        footer={[
          <>
            <ButtonV2 onClick={removeCancel} disabled={modalLoading}>
              Отменить
            </ButtonV2>
            <ButtonV2
              type="primary"
              onClick={() => handleDeleteAppClick(removeConfig.app.id)}
              loading={modalLoading}
            >
              Удалить
            </ButtonV2>
          </>,
        ]}
        maskClosable
      >
        {removeConfig.app && (
          <div className="removeWrapper">
            <div className="removeIcon">
              <WarningIcon />
            </div>
            <div className="removeTitle">
              <p>
                Внимание!
                <br />
                При удалении Приложения, у которого есть связь с Отзывами будут
                удалены все связанные с ним отзывы, вы действительно хотите
                удалить приложение "
                {removeConfig.app.title}
                "?
              </p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdminApplicationsPane;
